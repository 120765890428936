import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import SEO from 'components/SEO'
import Navbar from 'components/NavbarLiveStreaming'
import NavbarMobile from 'components/NavbarMobile'
import GlobalStyle from 'styles/global'
import theme from 'styles/theme'
import CookieConsent from 'react-cookie-consent'
import { useIntl } from 'gatsby-plugin-intl'
import './cookieContentStyles.css'
import WhatsApp from 'assets/images/svgs/whatsapp.svg'
import * as S from './styles'
import json from 'locales/pt.json'

const Layout = ({ children }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <SEO lang="pt-br" />
      <ThemeProvider theme={theme}>
        {/* <CookieConsent
          location="bottom"
          buttonText={formatMessage({ id: 'cookies.button' })}
          cookieName="gatsby-gdpr-google-analytics"
          style={{ padding: "0 30px", color: "000", background: "#2ee577", borderTop: "1px solid #fff" }}
          buttonClasses="cookieConsentButton"
          expires={150}
        >
          {formatMessage({ id: 'cookies.text' })}{" "}
        </CookieConsent> */}
        <GlobalStyle />
        <Navbar />
        <NavbarMobile />
        {/* <S.WhatsAppBubbleWrapper onClick={() => window.open(json.contact['group-5'].link, '_blank')}>
          <WhatsApp width={40} />
        </S.WhatsAppBubbleWrapper> */}
        {children}
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
