import React, { useEffect, useState } from "react";
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";
import { useCookies } from "react-cookie";
import Logo from "assets/images/svgs/logo.svg";
import * as S from "./Navbar.styles";

const Navbar = () => {
  const { formatMessage } = useIntl();
  const [, setCookie] = useCookies(["language"]);
  const [language, setLang] = useState("");

  useEffect(() => {
    const lang = window?.localStorage.getItem("gatsby-intl-language") || "pt";

    setLang(lang);
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoWrapper href="/">
          <Logo aria-label="TM1" />
        </S.LogoWrapper>
        <S.MenuNav>
          <S.MenuLinkUrl href={`/${language}/#aboutUs`}>
            {formatMessage({ id: "navbar.aboutUs" })}
          </S.MenuLinkUrl>
          <S.MenuLinkUrl href={`/${language}/pt/#whatWeDo`}>
            {formatMessage({ id: "navbar.whatWeDo" })}
          </S.MenuLinkUrl>
          <S.MenuLinkUrl href={`/${language}/#clients`}>
            {formatMessage({ id: "navbar.clients" })}
          </S.MenuLinkUrl>
          <S.MenuLink to={`/${language}/#cases`}>
            {formatMessage({ id: "navbar.cases" })}
          </S.MenuLink>
          <S.MenuLink to="/live-streaming">
            {formatMessage({ id: "navbar.liveStreaming" })}
          </S.MenuLink>
          <S.MenuLinkUrl href="https://tm1.gupy.io/" target="_blank">
            {formatMessage({ id: "navbar.work" })}
          </S.MenuLinkUrl>
          <S.MenuLink to={`/${language}/#contact`}>
            {formatMessage({ id: "navbar.contact" })}
          </S.MenuLink>
        </S.MenuNav>
        <S.LanguageWrapper>
          <IntlContextConsumer>
            {({ languages, language: currentLanguage }) =>
              languages.map((language) => (
                <S.LanguageButton
                  key={language}
                  active={currentLanguage === language}
                  onClick={() => {
                    setCookie("language", language, {
                      domain: "	.tm1.com.br",
                      path: "/",
                    });
                    changeLocale(language);
                  }}
                >
                  {language.toUpperCase()}
                </S.LanguageButton>
              ))
            }
          </IntlContextConsumer>
        </S.LanguageWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default Navbar;
