import React from "react";
import AboutUs from "components/AboutUs";
import Cases from "components/Cases";
import Clients from "components/Clients";
import Contact from "components/Contact";
import Live from "components/Live";
// import Like from 'components/Like' TO DO only from v2
import Header from "components/Header";
import Layout from "components/LayoutLiveStreaming";
import Video from "components/Video";
// import CasesMainCard from 'components/CasesMainCard' TO DO only from v2

const Index = () => (
  <Layout>
    <div style={{marginTop:120}} />
    <Live />
    {/* <Video /> */}
    {/* <CasesMainCard /> TO DO only from v2 */}
    {/* <Like /> TO DO only from v2 */}
    <Contact />
  </Layout>
);

export default Index;
